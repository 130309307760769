<template>
  <main id="content" role="main">
    <div class="bg-light">
      <div class="clearfix container space-bottom-2 space-top-3">
        <div class="bg-img-hero rounded">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 order-lg-2 mb-6 mb-lg-0">
                <div class="card">
                  <div class="card-body text-center p-5 py-lg-7">
                    <!-- <img class="img-fluid max-w-19rem mx-auto mb-3" src="@/assets/svg/illustrations/provider-crash-course-hero.png"> -->
                    <h3>Get the FREE Provider Crash Course</h3>
                    <p>Subscribe now and unlock the power to unleash the full potential of FabriXAPI Portal Platform.</p>
                    <!-- Begin Mailchimp Signup Form -->
                    <div id="mc_embed_signup">
                      <form id="mc-embedded-subscribe-form" action="https://fabrixapi.us18.list-manage.com/subscribe/post?u=b928e5178a4f8a241b088732f&amp;id=221f0de8c7&amp;f_id=003396e6f0" method="post" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                        <div class="row">
                          <div class="col col-md-6">
                            <!-- First name -->
                            <div class="mc-field-group w-100">
                              <label for="mce-FNAME">First Name <span class="asterisk">*</span>
                              </label>
                              <input id="mce-FNAME" type="text" name="FNAME" class="required text" value="" required="">
                            </div>
                          </div>
                          <div class="col col-md-6">
                            <!-- Last name -->
                            <div class="mc-field-group w-100">
                              <label for="mce-LNAME">Last Name <span class="asterisk">*</span>
                              </label>
                              <input id="mce-LNAME" type="text" name="LNAME" class="required text" value="" required="">
                            </div>
                          </div>
                        </div>
                        <!-- Email -->
                        <div class="mc-field-group w-100">
                          <label for="mce-EMAIL">Email Address <span class="asterisk">*</span>
                          </label>
                          <input id="mce-EMAIL" type="email" name="EMAIL" class="required email" required="" value="">
                        </div>
                        <div class="mb-4">
                          <p class="small text-center">By submitting your information, you agree to receive future communications from FabriXAPI.</p>
                        </div>
                        <div hidden="true"><input type="hidden" name="tags" value="2978432"></div>
                        <div id="mce-responses" class="clear">
                          <div id="mce-error-response" class="response" style="display: none;" />
                          <div id="mce-success-response" class="response" style="display: none;" />
                        </div>
                        <!-- // real people should not fill this in and expect good things - do not remove this or risk form bot signups -->
                        <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_b928e5178a4f8a241b088732f_221f0de8c7" tabindex="-1" value=""></div>
                        <div class="clear"><input id="mc-embedded-subscribe" type="submit" value="Get the Course" name="subscribe" class="btn btn-primary btn-wide border-0 mx-auto m-0"></div>
                      </form>
                    </div>
                  <!--End mc_embed_signup-->
                  </div>
                </div>
              </div>
              <div class="col-lg-6 order-lg-1">
                <div class="text-center mb-3">
                  <h2 class="mb-4">API Provider Crash Course Series</h2>
                  <img class="img-fluid max-w-35rem" src="@/assets/svg/illustrations/provider-crash-course-hero.png">
                </div>
                <p>Embark on your API journey with FabriXAPI! Unlock our online 4-week crash course series and master the art of treating your API as a product, week by week!</p>
                <p>Accelerate your progress with this 4-week crash course, covering essential topics:</p>
                <ul>
                  <li>Insider tips for launching your APIs</li>
                  <li>Enhancing and customizing your API Portal</li>
                  <li>Configuring API Portal settings with various monetization options</li>
                  <li>Strategies to boost your API exposure</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container space-2">
      <div class="text-center mb-6">
        <h2>Discover More API Tutorials</h2>
      </div>
      <div class="row">
        <div v-for="(item, index) in Blog" :key="index" class="col-md-6 col-lg-3 mb-4 mb-md-5">
          <!-- Card -->
          <a :href="$t(item.url)" class="card h-100 transition-3d-hover" target="_blank">
            <img class="card-img-top" :src="require('@/assets/img/resource-library/blog/' + `${ $t(item.image) }`)">
            <div class="card-body">
              <h4>{{ $t(item.title) }}</h4>
              <p class="small mb-0">{{ $t(item.description) }}</p>
            </div>
            <div class="card-footer border-0 py-2">
              <p class="text-primary font-weight-bold">Read More <i class="far fa-arrow-right ml-1" /></p>
            </div>
          </a>
        </div>
      </div>

    </div>
  </main>

</template>

<script>
export default {
  name: 'ProviderCrashCourse',
  components: {
  },
  data() {
    return {
      Blog: [
        {
          title: 'Monetizing APIs with Bring Your Own Stripe on FabriXAPI',
          description: 'The rise of API monetization has become a transformative force for businesses in the thriving digital marketplace..',
          image: 'fabrixapi-monetization.jpg',
          url: 'https://blog.openapihub.com/en-us/monetizing-apis-with-bring-your-own-stripe-on-fabrixapi/'
        },
        {
          title: '15 Minutes to Master the API Provider Journey with FabriXAPI',
          description: 'If you\'re just getting started with FabriXAPI and don\'t know where to begin, then this guide is for you...',
          image: 'fabrixapi-provider-missions.jpg',
          url: 'https://blog.openapihub.com/en-us/15-minutes-to-master-the-api-provider-journey-with-fabrixapi/'
        },
        {
          title: 'Get started with your API Subscription as an API Developer',
          description: 'If you\'re just getting started with any FabriXAPI-powered API Portal and don\'t know where to begin, then this guide is for you...',
          image: 'fabrixapi-developer-missions.jpg',
          url: 'https://blog.openapihub.com/en-us/get-started-with-your-api-subscription-as-an-api-developer/'
        },
        {
          title: 'What is an API Portal and why it matters?',
          description: 'API Portals (also known as Developer Portals) are an important part of any digital business that utilizes APIs...',
          image: 'what-is-api-portal.jpg',
          url: 'https://blog.openapihub.com/en-us/what-is-an-api-portal-and-why-it-matters/'
        }
      ]
    }
  },
  computed: {
  },
  mounted() {
  },
  created() {
  },
  methods: {
  },
  metaInfo() {
    return {
      title: 'FabriXAPI',
      titleTemplate: 'API Provider Crash Course | %s',
      link: [
        { rel: 'canonical', href: 'https://www.fabrixapi.com/resources/provider-crash-course' }
      ],
      meta: [
        { name: 'description', content: 'Discover the ' },
        { property: 'og:site_name', content: 'FabriXAPI' },
        { property: 'og:title', content: 'API Provider Crash Course | FabriXAPI' },
        { property: 'og:description', content: 'Master API journey as an API Provider on FabriXAPI.' },
        { property: 'og:image', content: 'https://www.fabrixapi.com/assets/img/thumbnails/fabrixapi-thumbnail.jpg' },
        { property: 'og:url', content: 'https://www.fabrixapi.com/resources/provider-crash-course' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
</style>
